const CaretDown = ({ size = 'medium', ...props }) => {
	return (
		<svg
			{...props}
			width={size === 'medium' ? 24 : 16}
			height={size == 'medium' ? 24 : 16}
			viewBox={size === 'medium' ? '0 0 24 24' : '0 0 24 24'}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.64645 10.0607C5.45118 9.8654 5.45118 9.54882 5.64645 9.35355L6.35355 8.64645C6.54882 8.45118 6.8654 8.45118 7.06066 8.64645L12 13.5858L16.9393 8.64645C17.1346 8.45118 17.4512 8.45118 17.6464 8.64645L18.3536 9.35355C18.5488 9.54882 18.5488 9.8654 18.3536 10.0607L12.3536 16.0607C12.1583 16.2559 11.8417 16.2559 11.6464 16.0607L5.64645 10.0607Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default CaretDown
